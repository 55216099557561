<template>

<div>

  <div v-if="index % 2 === 0" class="d-flex border-bottom justify-content-between p-3">

    <a  v-if="editMode" @click="selectItem">
      <div class="media ml-2">
        <div class="content ml-3">
          <h5 class="text-dark"
              @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
              @click="$emit('index',index)"
          >{{ emailTemplate.template_name }}</h5>
        </div>
      </div>
    </a>
    <div v-if="!editMode" class="form-group">
      <div class="position-relative">
        <input
            name="name"
            type="text"
            class="form-control "
            placeholder="Название шаблона :"
            v-model="emailTemplate.template_name"
        />
      </div>
    </div>
    <a class="btn btn-icon btn-outline-light" v-if="editMode" @click="editMode=!editMode"><edit-icon class="fea icon-sm"></edit-icon></a>
    <a class="btn btn-icon btn-outline-success" v-if="!editMode" @click="saveItem()"><check-icon class="fea icon-sm"></check-icon></a>
    <a class="btn btn-icon btn-outline-danger" v-if="!editMode" @click="deleteItem()"><trash-icon class="fea icon-sm"></trash-icon></a>
  </div>

  <div v-else class="d-flex border-bottom bg-light justify-content-between p-3">

    <a  v-if="editMode" @click="selectItem">
      <div class="media ml-2">
        <div class="content ml-3">
          <h5 class="text-dark"
              @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
              @click="$emit('index',index)"
          >{{ emailTemplate.template_name }}</h5>
        </div>
      </div>
    </a>
    <div v-if="!editMode" class="form-group">
      <div class="position-relative">
        <input
            name="name"
            type="text"
            class="form-control "
            placeholder="Название шаблона :"
            v-model="emailTemplate.template_name"
        />
      </div>
    </div>
    <a class="btn btn-icon btn-outline-light" v-if="editMode" @click="editMode=!editMode"><edit-icon class="fea icon-sm"></edit-icon></a>
    <a class="btn btn-icon btn-outline-success" v-if="!editMode" @click="saveItem()"><check-icon class="fea icon-sm"></check-icon></a>
    <a class="btn btn-icon btn-outline-danger" v-if="!editMode" @click="deleteItem()"><trash-icon class="fea icon-sm"></trash-icon></a>
  </div>


</div>


</template>

<script>

import { EditIcon,TrashIcon,CheckIcon } from "vue-feather-icons";

export default {
  name: "TemplateLine",
  components: {
    TrashIcon,EditIcon,CheckIcon,
  },
    props: {
      emailTemplate: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
  },
  data() {
    return{
      hover: false,
      editMode: true,
    }
  },
  methods: {
    deleteItem(){
      console.log('emiting delete')
      this.$emit('deleteItem',{template_id:this.emailTemplate.template_id});
    },
    saveItem() {
      console.log('emiting save')
      this.$emit('saveItem',{operationType:0,template_name:this.emailTemplate.template_name,template_id:this.emailTemplate.template_id});
      this.editMode = !this.editMode;
    },
    selectItem(){
      this.$emit('index',this.index);
    },
  }

}
</script>

<style scoped>

</style>
<template>

  <div>
    <Navbar :nav-light="true" />
    <br><br>
    <section class="section-two">

    <div class="row ml-4 mr-4">
      <div class="col-lg-3 col-md-6 col-12">
        <div class="sidebar sticky-bar mt-5 p-3 rounded shadow">
          <h5 class="d-flex justify-content-center">Шаблоны писем</h5>
          <a @click="addNewTemplate" class="btn btn-primary"> Добавить новый шаблон      </a>

         <!-- <div
              class="d-flex border-bottom align-items-center justify-content-between bg-light mt-4 p-3"
          >
            <h5>Шаблоны писем</h5>
            <a @click="addNewTemplate" class="btn btn-primary"> Создать </a>


          </div> -->

            <TemplateLine :emailTemplate="template" :index="ix" @index="updateListen"
                          @saveItem="saveItem" @deleteItem="deleteItem" v-for="(template,ix) in emailTemplates" />


        </div>
      </div>
      <div class="col-lg-9 col-md-6 col-12" v-if="templateSelected">
          <p>Шаблон: {{template_name_selected}}</p>
        <form>
          <div class="row mt-4">
            <div class="col-md-6">
              <div class="form-group">
                <label>От кого</label>
                <div class="position-relative">
                  <user-icon class="fea icon-sm icons"></user-icon>
                  <input
                      name="name"
                      id="namefrom"
                      type="text"
                      class="form-control pl-5"
                      placeholder="Введите имя :"
                      v-model="email.from_name"
                  />
                </div>
              </div>
            </div>
            <!--end col-->
            <div class="col-md-6">
              <div class="form-group">
                <label>Почта, куда вы получите ответы от соискателей</label>
                <div class="position-relative">
                  <mail-icon class="fea icon-sm icons"></mail-icon>
                  <input
                      name="email"
                      id="emailfrom"
                      type="email"
                      class="form-control pl-5"
                      placeholder="Введите email :"
                      v-model="email.from_email"
                  />
                </div>
              </div>
            </div>



            <div class="col-lg-12">
              <div class="form-group">
                <label>Тема письма</label>
                <div class="position-relative">
                  <bookmark-icon
                      class="fea icon-sm icons"
                  ></bookmark-icon>
                  <input
                      name="name"
                      id="subject"
                      type="text"
                      class="form-control pl-5"
                      placeholder="Тема :"
                      v-model="email.subject"
                  />
                </div>
              </div>
            </div>


            <div class="col-lg-12">
              <div class="form-group">
                <label>Текст письма <span class="badge badge-pill badge-outline-primary"> напишите first_name, чтобы поставить обращение в письме </span></label>
                <div class="position-relative">
                  <!--
                  <message-circle-icon
                      class="fea icon-sm icons"
                  ></message-circle-icon>
                  <textarea
                      name="comments"
                      id="comments"
                      class="form-control pl-5"
                      placeholder="Текст :"
                      v-model="email.emailBody"
                  ></textarea>
                  -->
                  <quill-editor
                      ref="editor"
                      v-model="email.emailBody"
                      :options="editorOption"
                  />

                </div>
              </div>
            </div>




          </div>
          <!--end row-->
          <!--end row-->
        </form>
        <!--end form-->




        <!-- Table Start -->
        <div class="col mt-4 pt-2" id="tables">
          <div class="component-wrapper rounded shadow">
            <div class="p-4 border-bottom">
              <h4 :class="spamFlag_color" > {{spamFlag}}</h4>
              <!--
              <div class="alert alert-success" role="alert">
                Данный шаблон прошел проверку на спам! Теперь его можно использовать для рассылки.
              </div>
              <div class="alert alert-warning" role="alert">
                Данная версия шаблона не прошла проверку на спам. Шаблон недоступен для рассылки.
              </div>
              <div class="alert alert-info" role="alert">
                Чтобы шаблон стал доступен для рассылки пройдите проверку на спам.
              </div>
              -->
              <h5 v-if="showSpamSuccess" class="text-success">
                Данный шаблон прошел проверку на спам! Теперь его можно использовать для рассылки.
              </h5>
              <h5 v-if="showSpamWarning" class="text-warning">
                Данная версия шаблона не прошла проверку на спам. Шаблон недоступен для рассылки.
              </h5>
              <h5 v-if="showSpamInfo" class="text-info">
                Чтобы шаблон стал доступен для рассылки пройдите проверку на спам.
              </h5>

            </div>

            <div class="p-4" v-if="spamResults.length>0">
              <div class="table-responsive bg-white shadow rounded">
                <table class="table mb-0 table-center">
                  <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Баллы</th>
                    <th scope="col">Описание</th>
                    <th scope="col">Стоп слова</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(spamResult,ix) in spamResults">
                    <th scope="row">{{ix+1}}</th>
                    <td>{{spamResult.score}}</td>
                    <td>{{spamResult.description}}</td>
                    <td>{{spamResult.stop_words}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
        <!-- Table End -->

        <div class="row mt-4">
          <div class="col-5">

            <a  @click="saveItem({operationType:1,template_name:template_name_selected,template_id:template_id_selected})" class="btn btn-primary"> Сохранить </a>

          </div>
          <div class="col-5 vs-con-loading__container" id="div-with-loading">

            <a  @click="checkSpam"  class="btn btn-warning"> Проверить на спам </a>

          </div>

        </div>


      </div>
    </div>


    </section>

    <Footer />

  </div>

</template>

<script>
import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";

import { MessageCircleIcon, MailIcon,UserIcon,BookmarkIcon,TrashIcon } from "vue-feather-icons";
import TemplateLine from "./TemplateLine";

import store from "@/store/store";

export default {
  name: "EmailToCandidates",
  components: {
    Navbar,
    Footer,
    MessageCircleIcon, MailIcon,UserIcon,BookmarkIcon,TrashIcon,
    TemplateLine,
  },
  data(){
    return{

      spamResults: [],
      spamFlag_color: '',
      spamFlag: '',

      showSpamInfo: false,
      showSpamWarning: false,
      showSpamSuccess: false,

      content: '<p><strong>Обязанности:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Требования:</strong></p><ul><li><br></li><li><br></li></ul><p><strong>Условия:</strong></p><ul><li><br></li><li><br></li></ul><p><br></p>',
      editorOption: {

        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ]
        }
      },



      templateSelected:false,
      emailTemplates:[],
      template_name_selected: '',
      template_id_selected: 0,

      emailTemplates_Options : [
        {id:1,value:'По дате добавления резюме'},
        {id:2,value:'По дате обновления резюме'},
        {id:3,value:'По убыванию зарплат'},
        {id:4,value:'По возрастанию зарплат'},
        {id:5,value:'По убыванию возраста'},
        {id:6,value:'По возрастанию возраста'},
      ],
      emailTemplates_Selected : {id:1,value:'По дате добавления резюме'},


      input_save_template: {
        user_id: 1,
        selectForm: 0,

        template_id:1,
        template_name: '',

        from_name: '',
        from_email: '',
        subject: '',
        emailBody: '',
        bcc_email: '',

        name_new: '',

        toDelete: 0,
        updateTemplateName: 0,
        updateEmail: 0,
        createTemplate:0,
      },

      email: {
        attachments:['a','b'],
        message: 'hello',
        time: '2018-01-01',
        sender: 'me',
        sender_name: 'igor',
        to: 'friend',
        from_email:'ivan@gmail.com',
        from_name:'Ivan Ivanov',
        bcc_email:'ivan@gmail.com',
        subject:'Senior Java Developer ',
        emailBody: '<p>Добрый день, first_name!</p><p><br></p><p>Иван из Skillrank, мы занимаемся поиском экспертов для технологических компаний.</p><p><br></p><p>Обратил внимание, что у вас большой опыт в Java. </p><p><br></p><p>Мы сейчас помогаем коллегам из&nbsp;XYZ c расширением их команды разботчиков в Москве.</p><p><br></p><p>Команде интересны люди с опытом в банкинге/платежных системах/консалтинге. Команда только формируется.</p><p><br></p><p>Оставлю формальное описание на google доке, чтобы не грузить письмо.</p><p><br></p><p>first_name, как вы смотрите на то, чтобы созвониться на этой неделе и обсудить подробности?</p><p>Дайте, пожалуйста, сигнал, если я не адресу или вы рассматриваете позиции другого профиля. Буду сильно признателен за любой ответ.</p><p><br></p><p><br></p><p>С уважением,</p><p>Иван</p>',

        id:1,
      },


      new_template: {
        from_email:'ivan@gmail.com',
        from_name:'Ivan Ivanov',
        subject:'Senior Java Developer ',
        emailBody: '',
        bcc_email:'ivan@gmail.com',
        name:'шаблон письма',
        id:1,
      },

      in_save: {
        id:1,
        name:'',
        template_id: 1,
        template_name: '',
      },

      in_sql_spam: {
        user_id: 1,
        template_id: 1,
        template_name: '',
        subjectEmail: '',
        textEmail: '',
      }

    }
  },
  mounted() {

    this.loadTemplateList();
    this.getSpam();
  },
  methods: {


    getSpam() {

      this.in_sql_spam.user_id = store.state.user_id;
      this.in_sql_spam.template_id = this.in_save.template_id;
      this.in_sql_spam.template_name = this.in_save.template_name

      this.axios({
        method: 'post',
        url:  'https://ai4banki.ru/api/spam-test/get',
        //url:  'http://localhost:8071/spam-test/get',
        data: this.in_sql_spam,
      }).then((res) => {
        console.log('inside getSpam')
        console.log(res.data)
          this.spamResults = res.data.resultTable;
          this.spamFlag = res.data.spamFlag;
         this.spamFlag_color = res.data.spamFlag_color;

        if (res.data.isSpamCheck !== 'not checked') {

          if (this.spamFlag_color.includes('success')) {
            this.showSpamSuccess = true;
            this.showSpamWarning = false;
            this.showSpamInfo = false;
          } else if (this.spamFlag_color.includes('warning')) {
            this.showSpamSuccess = false;
            this.showSpamWarning = true;
            this.showSpamInfo = false;
          } else {
            this.showSpamSuccess = false;
            this.showSpamWarning = false;
            this.showSpamInfo = true;
          }

        } else {
          this.showSpamSuccess = false;
          this.showSpamWarning = false;
          this.showSpamInfo = false;

        }
      });


    },

    checkSpam() {

      console.log(this.email)
      console.log(this.in_save)

     // this.$vs.loading({
     //   container: '#div-with-loading',
     //   scale: 0.5,
     //   opacity: 0.1,
     // });


      this.in_sql_spam.user_id = store.state.user_id;
      this.in_sql_spam.template_id = this.in_save.template_id;
      this.in_sql_spam.template_name = this.in_save.template_name;
      this.in_sql_spam.textEmail = this.email.emailBody ;
      this.in_sql_spam.subjectEmail = this.email.subject ;
      console.log(this.in_sql_spam)
      this.axios({
        method: 'post',
        url:  'https://ai4banki.ru/api/spam-test/check',
        //url:  'http://localhost:8071/spam-test/check',
        data: this.in_sql_spam,
      }).then((res) => {
        this.getSpam();
       // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      });


    },


    updateListen(e) {

      console.log('inside updateListen e = ' + e);

      this.email.emailBody = this.emailTemplates[e].emailBody;
      this.email.subject = this.emailTemplates[e].subject;
      this.email.from_email = this.emailTemplates[e].from_email;
      this.email.from_name = this.emailTemplates[e].from_name;
      this.template_name_selected = this.emailTemplates[e].template_name;
      this.template_id_selected = this.emailTemplates[e].template_id;
      this.in_save.id = e;
      this.in_save.name = this.template_name_selected;
      this.in_save.template_id = this.emailTemplates[e].template_id;
      this.in_save.template_name = this.emailTemplates[e].template_name;
      this.templateSelected = true;
      console.log(this.email);
      console.log(this.template_name_selected);

      this.getSpam();

    },

    loadTemplateList(){

      this.input_save_template.user_id = store.state.user_id;
      this.input_save_template.selectForm = 0;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/get-template-str?access_token=' + store.state.token,
        data: this.input_save_template,
      }).then((res) => {
        this.emailTemplates = res.data.arr;
        console.log(this.emailTemplates);
      });

    },

    saveItem(e){
      console.log(' inside saveItem ');
      console.log(e);
      this.input_save_template.user_id = store.state.user_id;

      this.input_save_template.template_id = e.template_id;
      this.input_save_template.template_name = e.template_name;
      this.input_save_template.toDelete = 0;
      this.input_save_template.updateTemplateName = 0;
      this.input_save_template.updateEmail = 0;
      this.input_save_template.createTemplate = 0;

      if (e.operationType ===1) {
        this.input_save_template.updateEmail = 1;
        this.input_save_template.from_email = this.email.from_email;
        this.input_save_template.from_name = this.email.from_name;
        this.input_save_template.subject = this.email.subject;
        this.input_save_template.emailBody = this.email.emailBody;
      } else if (e.operationType ===0) {
        this.input_save_template.updateTemplateName = 1;
      }


    //  console.log(this.input_save_template);


      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/save-template-str?access_token=' + store.state.token,
        data: this.input_save_template,
      }).then((res) => {
        if (res.data.status === 'Done!') {

              this.loadTemplateList();

          this.$bvToast.toast('Данные сохранены в базе', {
            title: `Сохранено`,
            variant: 'success',
            solid: true,
            autoHideDelay: 8000,
          });

        }
      });

    },

    deleteItem(e){

      this.input_save_template.user_id = store.state.user_id;
      this.input_save_template.template_id = e.template_id;
      this.input_save_template.name_new = '';

      this.input_save_template.toDelete = 1;
      this.input_save_template.updateTemplateName = 0;
      this.input_save_template.updateEmail = 0;
      this.input_save_template.createTemplate = 0;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/email/save-template-str?access_token=' + store.state.token,
        data: this.input_save_template,
      }).then((res) => {
        if (res.data.status === 'Done!') {
          this.loadTemplateList();
          this.templateSelected = false;
        }
      });

    },

    addNewTemplate(){

      this.input_save_template.user_id = store.state.user_id;

      this.input_save_template.toDelete = 0;
      this.input_save_template.updateTemplateName = 0;
      this.input_save_template.updateEmail = 0;
      this.input_save_template.createTemplate = 1;


          this.input_save_template.from_name = this.new_template.from_name;
          this.input_save_template.from_email = this.new_template.from_email;
          this.input_save_template.subject = this.new_template.subject;
          this.input_save_template.emailBody = this.new_template.emailBody;
          this.input_save_template.bcc_email = this.new_template.bcc_email;

          this.input_save_template.template_name = 'шаблон письма';
      this.input_save_template.name_new = 'шаблон письма';

          this.axios({
            method: 'post',
            url: '' + this.$hostname + '/email/save-template-str?access_token=' + store.state.token,
            data: this.input_save_template,
          }).then((res) => {
            if (res.data.status === 'Done!') {
              this.loadTemplateList();
            }
          });

    },


  },
}
</script>

<style scoped>

</style>